







































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
export default Vue.extend({
  props: {
    openSlackSteps: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    openSlackSteps() {
      this.dialog = true;
    },
  },
  methods: {},
});
