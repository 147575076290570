var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-x-hidden" },
            [
              _c(
                "v-form",
                {
                  ref: "selectionForm",
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _vm.mmpType == "kochava"
                        ? _c(
                            "v-card-title",
                            { staticClass: "text-h5 lighten-2" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("integration.integrateWith")) +
                                  " Kochava"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.mmpType == "branch"
                        ? _c(
                            "v-card-title",
                            { staticClass: "text-h5 lighten-2" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("integration.integrateWith")) +
                                  " Branch"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.mmpType == "custom"
                        ? _c(
                            "v-card-title",
                            { staticClass: "text-h5 lighten-2" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("integration.integrateWith")) +
                                  " Custom"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-4",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "div",
                    { staticClass: "field pa-6" },
                    [
                      _c("label", { staticClass: "black--text mb-3 d-flex" }, [
                        _vm._v(_vm._s(_vm.$t("createCampaign.chooseApp"))),
                      ]),
                      _c("v-autocomplete", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "persistent-hint": "",
                          hint: _vm.$t("integration.note"),
                          items: _vm.attrApps,
                          "item-text": "appName",
                          "return-object": "",
                          placeholder: _vm.$t("filter.app"),
                          rules: _vm.appRules,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                [
                                  _c("v-img", {
                                    staticClass: "rounded-lg",
                                    attrs: {
                                      color: "secondary",
                                      "lazy-src": data.item.appIcon,
                                      "max-width": "30",
                                      "max-height": "30",
                                      "min-width": "20",
                                      "min-height": "20",
                                      src: data.item.appIcon,
                                    },
                                  }),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "ml-2" },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(data.item.appName)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedApp,
                          callback: function ($$v) {
                            _vm.selectedApp = $$v
                          },
                          expression: "selectedApp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right pt-0",
                              attrs: { cols: "12", sm: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3 w-xsm-100 mb-2",
                                  staticStyle: { width: "120px" },
                                  attrs: { color: "disable", outlined: "" },
                                  on: { click: _vm.closeDialog },
                                },
                                [_vm._v(_vm._s(_vm.$t("kai.cancel")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3 w-xsm-100 mb-2",
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    color: "primary",
                                    elevation: "0",
                                    disabled: !_vm.form,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.generateApiKey()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("integration.integrate"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("GenerateApiKey", { attrs: { apiKeyData: _vm.apiKeyData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }