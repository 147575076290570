var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("div", { staticClass: "v-card__title headline v-modal-header " }, [
            _c("h5", { staticClass: "text-h6" }, [
              _vm._v(" Steps to Integrate & Test the Slack account"),
            ]),
          ]),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _vm._v("Step 1: Click on 'Add Integration' button "),
              _c("br"),
              _vm._v(" Step 2: Click on 'Slack' icon "),
              _c("br"),
              _vm._v(" Step 3: Enter the Slack Account credentials "),
              _c("br"),
              _vm._v(" Step 4: After successful authentication "),
              _c("br"),
              _c("div", { staticClass: "pl-11" }, [
                _vm._v(" Select the Slack Channel "),
                _c("br"),
                _vm._v(" Allow the File Read and write permissions "),
              ]),
              _vm._v(
                " Step 5: Go to your slack account and click on 'Search ads Maven test app' under “Apps” section in left Navigation. "
              ),
              _c("br"),
              _vm._v(
                " Step 6: Click on 'Search ads Maven test app' title and a popup will be shown "
              ),
              _c("br"),
              _vm._v(" Step 7: Click on '+ Add' the app to a channel "),
              _c("br"),
              _vm._v(
                " Step 8: Select the Channel from dropdown and click on 'add' button "
              ),
              _c("v-spacer"),
              _c("br"),
              _vm._v(
                " Note: The Channel should be the same channel that is select on Step 4 (During integration) "
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", depressed: "", width: "114" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }