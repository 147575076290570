




















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SEND_SLACK_MSG } from 'src/utils/apptweakqueries';
import InfoAlert from '../InfoAlert.vue';
import Vue from 'vue';
export default Vue.extend({
  props: {
    openSlackSteps: {
      type: Object,
      required: false,
    },
  },
  components: { InfoAlert },
  data() {
    return {
      dialog: false,
      integrationID: null,
      loadingAlertFlag: false,
      infoAlertFlag: false,
      infoAlertTitle: 'Error',
      infoAlertMessage:
        'Please provide access to the channel in Search Ads Maven App',
    };
  },
  watch: {
    openSlackSteps(data) {
      this.dialog = true;
      this.integrationID = data.integrationID;
    },
  },
  methods: {
    sendSlackMsg(intId) {
      this.loadingAlertFlag = true;
      this.$apollo
        .query({
          query: SEND_SLACK_MSG,
          variables: {
            change: Date.now(),
            intId: intId,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          if (res.data.sendSlackMsg.code === 1) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Access denied';
            this.infoAlertMessage = `You need to add channel ${res.data.sendSlackMsg.successMessage} to Search Ads Maven Test App manually. `;
          } else if (res.data.sendSlackMsg.code === 2) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'Channel not found. Might be deleted';
          } else if (res.data.sendSlackMsg.code === 3) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Authorization revoked';
            this.infoAlertMessage =
              'Authorization revoked. Please delete and add slack integration again';
          } else if (res.data.sendSlackMsg.code === 0) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Success';
            this.infoAlertMessage = `Sample report sent to channel ${res.data.sendSlackMsg.successMessage}`;
          } else if (res.data.sendSlackMsg.code === 5) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'File permissions were not provided';
          } else {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'Unable to send message to slack';
          }
        })
        .catch((error) => {
          this.loadingAlertFlag = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Error';
          this.infoAlertMessage = 'Unable to send message to slack';
        });
    },
  },
});
