















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { IntegrationTypes } from '@shared/enums';
import {
  GET_APP_EVENTS_LIST,
  GET_INTEGRATION,
  MMP_INTEGRATIONS,
} from '../utils/apollo-queries';
import { IMutationOptions } from 'src/utils/interfaces';
import DeleteIntegration from '../components/integration/DeleteIntegration.vue';
import DeleteMmpIntegration from '../components/integration/DeleteMmpIntegration.vue';
import { GET_OAUTH_URL } from 'src/utils/apollo-queries';
import { LSS } from '@core/services';
import InfoAlert from '../components/InfoAlert';
import SlackSteps from '../components/Integration-Steps/SlackSteps.vue';
import SlackStepsTest from '../components/Integration-Steps/SlackStepsTest.vue';
import {
  DELETE_KA,
  SEND_SLACK_MSG,
} from '../utils/apptweakqueries';
import SelectAttrApp from '../components/Integration-Steps/SelectAttrApp.vue';
import GenerateApiKey from '../components/Integration-Steps/GenerateApiKey.vue';
import ComingSoonPopup from '../components/Integration-Steps/ComingSoonPopup.vue';
import { updateCLF, getOwnApps } from '../utils/util';
import { MMP } from 'src/utils/enums';
import { SubscriptionPermissions } from '@shared/enums';
import PermissionPopup from './../components/PermissionPopup.vue';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import { decodeToken } from '@shared/utils/commom.util';
import * as moment from 'moment';
import { isReadOnly} from '../utils/util';
import { logActivity } from 'src/utils/sam-activity-log';
export default Vue.extend({
  name: 'addIntegration',
  components: {
    DeleteIntegration,
    DeleteMmpIntegration,
    InfoAlert,
    SlackSteps,
    SlackStepsTest,
    SelectAttrApp,
    GenerateApiKey,
    ComingSoonPopup,
    PermissionPopup,
    PermissionReadOnlyPopup
  },
  data() {
    return {
      //activity log messages
      logStartType:"activity",
      logStartMessage:"Integration start",
      logFailureMessage:"Integration fail",
      featuredName: "ASA Integration",
      logFailureType:'failure',
      featuredPath: '',
      permissionReadOnlyMetadata:{},
      SubscriptionPermissionsEnums: SubscriptionPermissions,
      permissionMetadata:{},
      showComms: [],
      showApples: [],
      infoAlertFlag: false,
      infoAlertTitle: 'Error',
      infoAlertMessage:
        'Please provide access to the channel in Search Ads Maven App',
      loadingAlertFlag: false,
      msgTitle: '',
      message: '',
      dialogapps: false,
      itemsPerRow: 3,
      integrationsByTypes: {},
      loadingState: false,
      drawer: true,
      fileName: null,
      getIntegration: [],
      integrationData: null,
      mmpIntegrationData: null,
      mini: true,
      e1: 1,
      saving: false,
      msgModel: false,

      dialog: false,
      dialog2: false,

      selectedApp: [],
      searchedApps: [],
      loadingApps: false,
      vSelectRules: [(v) => v != null || 'Please select'],
      intApps: [],
      icons: [],
      intAppsTemp: [],
      fullscreenLoader: null,
      openSlackStepsData: null,
      openSlackStepsTestData: null,
      mmpIntegs: [],
      selectAttrAppsData: null,
      apiKeyData: null,
      comingSoonMetadata: null,
      MMP: MMP,
      deleteIntStatus:[],
    };
  },
  // apollo: {
  //   getIntegration: {
  //     query: GET_INTEGRATION,
  //     skip(): boolean {
  //       return false;
  //     },
  //     fetchPolicy: 'no-cache',
  //     // update(data) {
  //     //   console.log(data.getIntegration)
  //     //   this.integrationsByTypes = Object.create({});
  //     //   const sorted = data.getIntegration.sort((a, b) =>
  //     //     a.type.localeCompare(b.type)
  //     //   );
  //     //   for (const integration of sorted) {
  //     //     if (this.integrationsByTypes[integration.type]) {
  //     //       this.integrationsByTypes[integration.type].push(integration);
  //     //     } else {
  //     //       this.integrationsByTypes[integration.type] = [integration];
  //     //     }
  //     //   }
  //     //   console.log(this.integrationsByTypes)
  //     // },
  //   },
  // },
  watch:{
    deleteIntStatus: {
    handler(newVal) {
      this.$nextTick(() => {
        if (this.integrationGroup) {
          this.shouldShowTooltip(this.integrationGroup.IntegrationID, newVal);
        }
      });
    },
    deep: true 
  }
  },
  computed:{
permissions:{
      cache: false,
      get(){
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            return [];
           }
          else{
            return decodeToken(LSS.token).permissions?decodeToken(LSS.token).permissions:[];
          }
        
      }
      }
    },
    
    isSuperAdmin:{
      cache:false,
      get(){
          if (decodeToken(LSS.token) && decodeToken(LSS.token).Role == 'SUPERADMIN') {
              return true
          }
          return false
      }
    },
  },
  methods: {
    shouldShowTooltip(integrationId,deleteIntStatus) {
      if(LSS.deleteIntStatus.length>0){
        return LSS.deleteIntStatus.some(status => status.integrationId === integrationId );
      }else{
        return false
      }
      
    },
    deleteStatusChange(ids){
       this.deleteIntStatus = ids;
     },
    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
    openPermissionPopup(){
      this.permissionMetadata={
        date: moment.now()
      }
    },


    getNonMMPInts(){
      this.getIntegration = [];
      this.$apollo
        .query({
          query: GET_INTEGRATION,
          variables: {},
        })
        .then((res) => {
          // console.log(res.data)
          this.getIntegration = [];
          this.getIntegration = res.data.getIntegration
          this.showComms =  res.data.getIntegration.filter(e => e.type == 'SLACK')
          this.showApples = res.data.getIntegration.filter(e => e.type == 'APPLE_SEARCH_ADS')
          // console.log(this.showComms)
          // console.log(this.showApples)
          if(this.showApples.length == 0){
            LSS.asaInt = false
          }else{
            LSS.asaInt = true
          }
        })
        .catch((error) => {
          // this.fullscreenLoader.hide()
        });
    },
    sendSlackMsg(intId) {
      this.loadingAlertFlag = true;
      this.$apollo
        .query({
          query: SEND_SLACK_MSG,
          variables: {
            change: Date.now(),
            intId: intId,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          if (res.data.sendSlackMsg.code === 1) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Access denied';
            this.infoAlertMessage = `You need to add channel ${res.data.sendSlackMsg.successMessage} to Search Ads Maven Test App manually. `;
          } else if (res.data.sendSlackMsg.code === 2) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'Channel not found. Might be deleted';
          } else if (res.data.sendSlackMsg.code === 3) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Authorization revoked';
            this.infoAlertMessage =
              'Authorization revoked. Please delete and add slack integration again';
          } else if (res.data.sendSlackMsg.code === 0) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Success';
            this.infoAlertMessage = `Sample report sent to channel ${res.data.sendSlackMsg.successMessage}`;
          } else if (res.data.sendSlackMsg.code === 5) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'File permissions were not provided';
          } else {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error';
            this.infoAlertMessage = 'Unable to send message to slack';
          }
        })
        .catch((error) => {
          this.loadingAlertFlag = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Error';
          this.infoAlertMessage = 'Unable to send message to slack';
        });
    },
    notSupported() {
      alert('Feature coming soon!!!');
    },
    goToKochava() {
      const url =
        'https://www.kochava.com/sign-up-free-app-analytics/?int-link=page-free-app-analytics';
      window.open(url, '_blank').focus();
    },
    changeApp(e) {
      //console.log(e)
    },
    goToKA() {
      LSS.kaIntApps = this.selectedApp;
      return this.$router.push('/kochava-integration');
    },
    getIterationArray(array: any[]): any[] {
      return Array(Math.ceil(array.length / this.itemsPerRow));
    },
    openDeleteIntegrationDialog(id, type) {
      const TeamID = decodeToken(LSS.token).TeamID
      this.integrationData = { id: id, TeamID: TeamID, type: type, now: Date.now() };
    },
    openDeleteMmpIntegrationDialog(mmpId, appId) {
      this.mmpIntegrationData = { mmpId, appId, now: Date.now() };
    },
    async runMutation(mutationOptions: IMutationOptions) {
      return await this.$apollo.mutate({
        mutation: mutationOptions.mutationQuery,
        variables: mutationOptions.variables,
      });
    },
    async getOAuthUrl(type: IntegrationTypes) {
      //activity logs When Apple Search Ads + integration is clicked
     await logActivity(this.logStartType,this.logStartMessage,this.featuredName,this.featuredPath,{});
      //end of activity log
      try {
        const result = await this.$apollo.query({
          query: GET_OAUTH_URL,
          variables: {
            req: { type, referrer: this.$route.name },
          },
        });

       if(type==IntegrationTypes.APPLE_SEARCH_ADS && result.data.getOAuthState.url==""){
        this.$notify.success('An ASA integration is being deleted in the background, please wait');
       }else{
        location.href = result.data.getOAuthState.url;
       }
       
      } catch (error) {
         //activity logs When When Apple Search Ads integration fails:
        const errorMsg = {
          "detail":this.$t('successMsg.signinFailMsg')
        };
        await logActivity(this.logFailureType,this.logFailureMessage,this.featuredName,this.featuredPath,errorMsg);
        //activity log ends
        this.$notify.error(this.$t('successMsg.signinFailMsg'));
      }
    },
    getIntegrationLogo(type: string) {
      let name: string;
      switch (type) {
        case IntegrationTypes.ADJUST: {
          name = 'adjust.png';
          break;
        }
        case IntegrationTypes.APPLE_SEARCH_ADS: {
          name = 'apple.png';
          break;
        }
        case IntegrationTypes.APPSFLYER: {
          name = 'appsflyer.png';
          break;
        }
        case IntegrationTypes.KOCHAVA: {
          name = 'kochava.png';
          break;
        }
        case IntegrationTypes.SINGULAR: {
          name = 'singular.png';
          break;
        }
        case IntegrationTypes.SLACK: {
          name = 'slack.png';
          break;
        }
        case IntegrationTypes.TENJIN: {
          name = 'tenjin.png';
          break;
        }
      }
      return require('../assets/images/' + name);
    },
    closeMsgModel() {
      this.msgModel = false;
    },
    reloadWindow() {
      window.location.reload();
    },
    openSlackSteps() {
      this.openSlackStepsData = {
        now: Date.now(),
      };
    },
    openSlackStepsTest(integrationID) {
      this.openSlackStepsTestData = {
        now: Date.now(),
        integrationID: integrationID,
      };
    },
    getMMPIntegrations() {
      // this.loadingApps = true
      this.fullscreenLoader = this.$loading.show()
      this.mmpIntegs = [];
      this.$apollo
        .query({
          query: MMP_INTEGRATIONS,
          variables: {},
        })
        .then((res) => {
          // console.log(res.data)
          this.mmpIntegs = [];
          const integs = res.data.listAttrIntegrations;
          this.mmpIntegs = integs.map((e) => {
            return {
              appIcon:
                'https://lh3.googleusercontent.com/a/AATXAJzYWkU2rjXsKCR0EaOKK0h2eAn9avNJhAHSgy_M=s96-c',
              ...e,
            };
          });
          integs.map((e, i) => {
            if(LSS.ownApps && LSS.ownApps.length > 0) {
              for (let index = 0; index < LSS.ownApps.length; index++) {
                const element = LSS.ownApps[index];      
                if(element.adamId == e.appId){
                  this.mmpIntegs[i].appIcon = element.appIcon
                }
              }
            }
          });
          console.log(this.mmpIntegs)
          this.fullscreenLoader.hide()
        })
        .catch((error) => {
          // this.loadingApps = false
          this.fullscreenLoader.hide()
        });
    },
    openSelectAttrApp( mmp ) {
      try {
          const asaInt = LSS.asaInt;
          const firstSyncDone = LSS.firstSyncDone;

          if(asaInt && firstSyncDone) {
              this.selectAttrAppsData = {
              date: Date.now(),
              mmpType: mmp
              };
          }
          else{
                this.openCommingSoonPopup('kochava')
          }
          
          
      } catch (error) {
          this.$notify.error('Error while fetching first Sync Status');
          console.log(error);
        }
    },
    openGenerateApiKey(data) {
      const appDetails = {
        appName: data.appName,
        adamId: data.appId,
        appIcon: data.appIcon,
      };
      this.apiKeyData = {
        from: 'AddIntegration',
        selectedApp: appDetails,
        apiKey: data.apiKey,        
        mmpId: data.mmpId,        
        appEvents: data.appEvents || []
      };
    },
    openCommingSoonPopup(data) {
      this.comingSoonMetadata = {
        mmpName: data,
      };
    },
  },
  mounted() {
    if(!LSS.deleteIntStatus){
      LSS.deleteIntStatus = [];
    } 
    this.getNonMMPInts();
    this.getMMPIntegrations();
    this.deleteIntStatus = LSS.deleteIntStatus;
    if (localStorage.getItem('isFromAdsManager')) {
      this.dialog = true;
      localStorage.removeItem('isFromAdsManager');
    }
    this.$root.$on('mmpIntAdded', async (appId,mmpId) => {
      await getOwnApps('addInt', this)
      this.getMMPIntegrations();   
      if(LSS.ownApps && LSS.ownApps.length > 0) { 
        let lssApps = [...LSS.ownApps]
        for (let index = 0; index < lssApps.length; index++) {
          const element = lssApps[index];  
          if(element.adamId == appId){
            lssApps[index].attrExist = true
            lssApps[index].attrType = mmpId
          }
        }        
        LSS.ownApps = []
        LSS.ownApps = lssApps.filter(e => e.adamId != 0)
      }
    });  

    this.$root.$on('asaIntegrationDeleted', () => {
      this.getNonMMPInts();
      this.getMMPIntegrations();   
      if(LSS.deleteIntStatus.length>0){
      
        const filteredDeleteIntStatus = LSS.deleteIntStatus.filter(status =>
            this.getIntegration.some(item => item.IntegrationID === status.integrationId)
        );
        LSS.deleteIntStatus = filteredDeleteIntStatus;
        this.deleteIntStatus = LSS.deleteIntStatus;
        
        }  
    });
  },
});
