var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard " },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            {
              staticClass:
                "inner-container only-heading-part header-with-banner",
            },
            [
              _c(
                "v-container",
                {
                  staticClass:
                    "v-main-content stepper-template only-simple-content",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12", lg: "12" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      sm: "12",
                                      md: "6",
                                      lg: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          " rounded-lg h-100 pa-4 pb-8 extra-padding v-card v-sheet theme--light",
                                        attrs: { elevation: "3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "box-header chart-header-area mb-2",
                                          },
                                          [
                                            _c("h3", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("integration.assPart")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "mx-0" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "text-p black--text ml-5 mt-1",
                                              },
                                              [_vm._v("Apple")]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  lg: "12",
                                                  md: "12",
                                                  sm: "12",
                                                  xl: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "pa-5 rounded-lg outline extra-padding border-bottom-primary col-min-height",
                                                    attrs: {
                                                      flat: "",
                                                      elevation: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      { staticClass: "pa-0" },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            },
                                                            !_vm.isReadOnly()
                                                              ? {
                                                                  click: function () {
                                                                    return _vm.getOAuthUrl(
                                                                      "APPLE_SEARCH_ADS"
                                                                    )
                                                                  },
                                                                }
                                                              : {
                                                                  click: function () {
                                                                    return _vm.openPermissionReadOnlyPopup()
                                                                  },
                                                                }
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "text-with-delete",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-overflow text-xsm-body-2   lighten-2 ",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Apple Search Ads "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      plain: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mx-1 grey--text lighten-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "mx-0" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "text-p black--text ml-5 mt-1",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "integration.communication"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.getIntegration,
                                              function (
                                                integrationGroup,
                                                key1
                                              ) {
                                                return void 0
                                              }
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  lg: "12",
                                                  md: "12",
                                                  sm: "12",
                                                  xl: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "pa-5 rounded-lg outline extra-padding border-bottom-primary col-min-height",
                                                    attrs: {
                                                      flat: "",
                                                      elevation: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      { staticClass: "pa-0" },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            },
                                                            !_vm.isReadOnly()
                                                              ? {
                                                                  click: function () {
                                                                    return _vm.getOAuthUrl(
                                                                      "SLACK"
                                                                    )
                                                                  },
                                                                }
                                                              : {
                                                                  click: function () {
                                                                    return _vm.openPermissionReadOnlyPopup()
                                                                  },
                                                                }
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "text-with-delete",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-xsm-body-2  lighten-2 ",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Slack "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      plain: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mx-1 grey--text lighten-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "mx-0" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "text-p black--text ml-5 mt-1 ",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("integration.mmp")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {}
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : {}
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/kochava.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "text-with-delete",
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer",
                                                                    },
                                                                  },
                                                                  _vm.isSuperAdmin
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openSelectAttrApp(
                                                                            "kochava"
                                                                          )
                                                                        },
                                                                      }
                                                                    : _vm.permissions.includes(
                                                                        _vm
                                                                          .SubscriptionPermissionsEnums
                                                                          .ATTRINTEGRATION
                                                                      ) == false
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openPermissionPopup()
                                                                        },
                                                                      }
                                                                    : !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openSelectAttrApp(
                                                                            "kochava"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Kochava"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {}
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : {}
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/branch_logo.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "text-with-delete",
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openSelectAttrApp(
                                                                            "branch"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Branch"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Adjust"
                                                              )
                                                            },
                                                          }
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : !_vm.isReadOnly()
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Adjust"
                                                              )
                                                            },
                                                          }
                                                        : {
                                                            click: function () {
                                                              return _vm.openPermissionReadOnlyPopup()
                                                            },
                                                          }
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/adjust.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-with-delete",
                                                                },
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Adjust"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "AppsFlyer"
                                                              )
                                                            },
                                                          }
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : !_vm.isReadOnly()
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "AppsFlyer"
                                                              )
                                                            },
                                                          }
                                                        : {
                                                            click: function () {
                                                              return _vm.openPermissionReadOnlyPopup()
                                                            },
                                                          }
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/appsflyer.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-with-delete",
                                                                },
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "AppsFlyer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Tenjin"
                                                              )
                                                            },
                                                          }
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : !_vm.isReadOnly()
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Tenjin"
                                                              )
                                                            },
                                                          }
                                                        : {
                                                            click: function () {
                                                              return _vm.openPermissionReadOnlyPopup()
                                                            },
                                                          }
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/tenjin.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-with-delete",
                                                                },
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Tenjin"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Singular"
                                                              )
                                                            },
                                                          }
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : !_vm.isReadOnly()
                                                        ? {
                                                            click: function () {
                                                              return _vm.openCommingSoonPopup(
                                                                "Singular"
                                                              )
                                                            },
                                                          }
                                                        : {
                                                            click: function () {
                                                              return _vm.openPermissionReadOnlyPopup()
                                                            },
                                                          }
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/singular.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-with-delete",
                                                                },
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Singular"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "12",
                                                    md: "12",
                                                    sm: "12",
                                                    xl: "12",
                                                    cols: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        class: [
                                                          "pa-2 rounded-lg outline border-bottom-primary col-min-height",
                                                          _vm.isSuperAdmin
                                                            ? ""
                                                            : _vm.permissions.includes(
                                                                _vm
                                                                  .SubscriptionPermissionsEnums
                                                                  .ATTRINTEGRATION
                                                              ) == false
                                                            ? "disabled-class"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          elevation: "3",
                                                        },
                                                      },
                                                      _vm.isSuperAdmin
                                                        ? {}
                                                        : _vm.permissions.includes(
                                                            _vm
                                                              .SubscriptionPermissionsEnums
                                                              .ATTRINTEGRATION
                                                          ) == false
                                                        ? {
                                                            click: function () {
                                                              return _vm.openPermissionPopup()
                                                            },
                                                          }
                                                        : {}
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                size: "35",
                                                                color: "none",
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  "max-width":
                                                                    "60",
                                                                  src: require("../assets/images/custom.png"),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "text-with-delete",
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openSelectAttrApp(
                                                                            "custom"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c("h6", {}, [
                                                                    _vm._v(
                                                                      "Custom"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mx-1 grey--text lighten-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.showComms.length > 0 ||
                                _vm.showApples.length > 0 ||
                                _vm.mmpIntegs.length > 0
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "6",
                                          lg: "8",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "rounded-lg h-100 pa-4 pb-8 extra-padding v-card v-sheet theme--light",
                                            attrs: { elevation: "3" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area mb-2",
                                              },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "integration.completeInt"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm.showApples.length > 0
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "mx-0" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-p black--text ml-5 mt-1",
                                                      },
                                                      [_vm._v("Apple")]
                                                    ),
                                                    _vm._l(
                                                      _vm.getIntegration,
                                                      function (
                                                        integrationGroup,
                                                        key1
                                                      ) {
                                                        return [
                                                          integrationGroup.type ==
                                                          "APPLE_SEARCH_ADS"
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  key: key1,
                                                                  attrs: {
                                                                    lg: "12",
                                                                    md: "12",
                                                                    sm: "12",
                                                                    xl: "12",
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2 rounded-lg outline border-bottom-primary active col-min-height",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        elevation:
                                                                          "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-avatar",
                                                                            {
                                                                              attrs: {
                                                                                size:
                                                                                  "40",
                                                                                color:
                                                                                  "none",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    size:
                                                                                      "45",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-apple"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            {
                                                                              staticClass:
                                                                                "text-right",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-with-delete",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-overflow text-xsm-body-2 d-flex w-100",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          integrationGroup.name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  integrationGroup.invalid
                                                                                    ? _c(
                                                                                        "v-tooltip",
                                                                                        {
                                                                                          attrs: {
                                                                                            bottom:
                                                                                              "",
                                                                                            color:
                                                                                              "primarydark",
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function (
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  var attrs =
                                                                                                    ref.attrs
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      _vm._g(
                                                                                                        _vm._b(
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "red",
                                                                                                            },
                                                                                                          },
                                                                                                          "v-icon",
                                                                                                          attrs,
                                                                                                          false
                                                                                                        ),
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-alert-outline"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "addIntegration.invalid"
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm.shouldShowTooltip(
                                                                                    integrationGroup.IntegrationID,
                                                                                    _vm.deleteIntStatus
                                                                                  )
                                                                                    ? _c(
                                                                                        "v-tooltip",
                                                                                        {
                                                                                          attrs: {
                                                                                            top:
                                                                                              "",
                                                                                            color:
                                                                                              "primarydark",
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function (
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          ref:
                                                                                                            "icon",
                                                                                                          refInFor: true,
                                                                                                          staticClass:
                                                                                                            "mx-1 icon-hover",
                                                                                                          staticStyle: {
                                                                                                            cursor:
                                                                                                              "pointer",
                                                                                                          },
                                                                                                          attrs: {
                                                                                                            color:
                                                                                                              "primary",
                                                                                                          },
                                                                                                        },
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-delete-outline"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "integration.intDeletionInProgress"
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "v-icon",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-1 icon-hover",
                                                                                            staticStyle: {
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            },
                                                                                            attrs: {
                                                                                              color:
                                                                                                "primary",
                                                                                            },
                                                                                          },
                                                                                          !_vm.isReadOnly()
                                                                                            ? {
                                                                                                click: function () {
                                                                                                  return _vm.openDeleteIntegrationDialog(
                                                                                                    integrationGroup.IntegrationID,
                                                                                                    integrationGroup.type
                                                                                                  )
                                                                                                },
                                                                                              }
                                                                                            : {
                                                                                                click: function () {
                                                                                                  return _vm.openPermissionReadOnlyPopup()
                                                                                                },
                                                                                              }
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-delete-outline"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.showComms.length > 0
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "mx-0" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-p black--text ml-5 mt-1 ",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "integration.communication"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.getIntegration,
                                                      function (
                                                        integrationGroup,
                                                        key1
                                                      ) {
                                                        return [
                                                          integrationGroup.type ==
                                                          "SLACK"
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  key: key1,
                                                                  attrs: {
                                                                    lg: "12",
                                                                    md: "12",
                                                                    sm: "12",
                                                                    xl: "12",
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2 rounded-lg outline border-bottom-primary active col-min-height",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        elevation:
                                                                          "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-avatar",
                                                                            {
                                                                              attrs: {
                                                                                size:
                                                                                  "35",
                                                                                color:
                                                                                  "none",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-img",
                                                                                {
                                                                                  attrs: {
                                                                                    "max-width":
                                                                                      "60",
                                                                                    src: require("../assets/images/slack.png"),
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  "",
                                                                                color:
                                                                                  "primarydark",
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function (
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "span",
                                                                                          _vm._g(
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-overflow text-xsm-body-2 d-flex",
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                integrationGroup.name
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      integrationGroup.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            {
                                                                              staticClass:
                                                                                "text-right",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-with-delete",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-1 icon-hover",
                                                                                        staticStyle: {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        },
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary",
                                                                                        },
                                                                                      },
                                                                                      !_vm.isReadOnly()
                                                                                        ? {
                                                                                            click: function () {
                                                                                              return _vm.openDeleteIntegrationDialog(
                                                                                                integrationGroup.IntegrationID,
                                                                                                integrationGroup.type
                                                                                              )
                                                                                            },
                                                                                          }
                                                                                        : {
                                                                                            click: function () {
                                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                                            },
                                                                                          }
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-subtitle",
                                                                                [
                                                                                  integrationGroup.type ===
                                                                                  "SLACK"
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          staticStyle: {
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          },
                                                                                          attrs: {
                                                                                            text:
                                                                                              "",
                                                                                            color:
                                                                                              "primary",
                                                                                            "x-small":
                                                                                              "",
                                                                                          },
                                                                                          on: {
                                                                                            click: function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.openSlackStepsTest(
                                                                                                integrationGroup.IntegrationID
                                                                                              )
                                                                                            },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "integration.intStep"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.mmpIntegs.length > 0
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "mx-0" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-p black--text ml-5 mt-1 ",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "integration.mmp"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.mmpIntegs,
                                                      function (int, i) {
                                                        return _c(
                                                          "v-col",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              lg: "12",
                                                              md: "12",
                                                              sm: "12",
                                                              xl: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "py-3 px-3 rounded-lg outline border-bottom-primary col-min-height disabled-classs",
                                                                attrs: {
                                                                  flat: "",
                                                                  elevation:
                                                                    "3",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event.target !==
                                                                      $event.currentTarget
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    return _vm.openGenerateApiKey(
                                                                      int
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    staticClass:
                                                                      "px-0 pt-1 d-flex align-center w-100",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-center",
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openGenerateApiKey(
                                                                              int
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        int.mmpId ==
                                                                        _vm.MMP
                                                                          .KOCHAVA_ID
                                                                          ? _c(
                                                                              "v-img",
                                                                              {
                                                                                attrs: {
                                                                                  width:
                                                                                    "30",
                                                                                  height:
                                                                                    "30",
                                                                                  src: require("../assets/images/kochava.png"),
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        int.mmpId ==
                                                                        _vm.MMP
                                                                          .BRANCH_ID
                                                                          ? _c(
                                                                              "v-img",
                                                                              {
                                                                                attrs: {
                                                                                  width:
                                                                                    "30",
                                                                                  height:
                                                                                    "30",
                                                                                  src: require("../assets/images/branch_logo.png"),
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        int.mmpId ==
                                                                        _vm.MMP
                                                                          .CUSTOM_ID
                                                                          ? _c(
                                                                              "v-img",
                                                                              {
                                                                                attrs: {
                                                                                  width:
                                                                                    "30",
                                                                                  height:
                                                                                    "30",
                                                                                  src: require("../assets/images/custom.png"),
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "integrate-app d-flex align-center",
                                                                          },
                                                                          [
                                                                            int
                                                                              .appEvents
                                                                              .length ==
                                                                            0
                                                                              ? _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-2",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " mdi-timer-sand"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            int
                                                                              .appEvents
                                                                              .length !=
                                                                            0
                                                                              ? _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-2",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "success",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " mdi-check"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "v-img",
                                                                              {
                                                                                attrs: {
                                                                                  src:
                                                                                    int.appIcon,
                                                                                  "lazy-src":
                                                                                    int.appIcon,
                                                                                  width:
                                                                                    "30",
                                                                                  height:
                                                                                    "30",
                                                                                },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex flex-column align-start ml-3",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h6",
                                                                                  {
                                                                                    staticClass:
                                                                                      "line-height-sm whitespace-nowrap",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          int.appName
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                int
                                                                                  .appEvents
                                                                                  .length !=
                                                                                0
                                                                                  ? _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text mt-0 mb-0 whitespace-nowrap",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "integration.receivedEventType"
                                                                                              )
                                                                                            ) +
                                                                                            ": " +
                                                                                            _vm._s(
                                                                                              int
                                                                                                .appEvents
                                                                                                .length
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                int
                                                                                  .appEvents
                                                                                  .length ==
                                                                                0
                                                                                  ? _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text mt-0 mb-0 whitespace-nowrap",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "integration.waitForEvents"
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-end w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "icon-hover",
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer",
                                                                              },
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            },
                                                                            !_vm.isReadOnly()
                                                                              ? {
                                                                                  click: function () {
                                                                                    return _vm.openDeleteMmpIntegrationDialog(
                                                                                      int.mmpId,
                                                                                      int.appId
                                                                                    )
                                                                                  },
                                                                                }
                                                                              : {
                                                                                  click: function () {
                                                                                    return _vm.openPermissionReadOnlyPopup()
                                                                                  },
                                                                                }
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete-outline"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c("DeleteIntegration", {
                attrs: { integrationDetailsForDeletion: _vm.integrationData },
                on: { emitDeleteStatus: _vm.deleteStatusChange },
              }),
              _c("DeleteMmpIntegration", {
                attrs: {
                  integrationDetailsForDeletion: _vm.mmpIntegrationData,
                },
              }),
              _c("SlackSteps", {
                attrs: { openSlackSteps: _vm.openSlackStepsData },
              }),
              _c("SlackStepsTest", {
                attrs: { openSlackSteps: _vm.openSlackStepsTestData },
              }),
              _c("SelectAttrApp", {
                attrs: { selectAttrAppsData: _vm.selectAttrAppsData },
              }),
              _c("GenerateApiKey", { attrs: { apiKeyData: _vm.apiKeyData } }),
              _c("ComingSoonPopup", {
                attrs: { comingSoonMetadata: _vm.comingSoonMetadata },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loadingState,
                    callback: function ($$v) {
                      _vm.loadingState = $$v
                    },
                    expression: "loadingState",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v("Processing...")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "cust" },
                        [
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "300", persistent: "" },
                  model: {
                    value: _vm.msgModel,
                    callback: function ($$v) {
                      _vm.msgModel = $$v
                    },
                    expression: "msgModel",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v(_vm._s(_vm.msgTitle))]
                      ),
                      _c("v-card-text", [_vm._v(_vm._s(_vm.message))]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeMsgModel()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("kai.ok")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "362" },
                  model: {
                    value: _vm.loadingAlertFlag,
                    callback: function ($$v) {
                      _vm.loadingAlertFlag = $$v
                    },
                    expression: "loadingAlertFlag",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticStyle: { padding: "7px 0px" },
                      attrs: { color: "primary" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "cust", attrs: { color: "primary" } },
                        [
                          _c("label", { staticStyle: { color: "white" } }, [
                            _vm._v("Sending Message. Just a moment"),
                          ]),
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: {
                              indeterminate: "",
                              color: "white",
                              stream: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("InfoAlert", {
            attrs: {
              InfoAlert: _vm.infoAlertFlag,
              InfoAlertTitle: _vm.infoAlertTitle,
              InfoAlertMessage: _vm.infoAlertMessage,
            },
            on: {
              emitInfoAlertOkClicked: function ($event) {
                _vm.infoAlertFlag = false
              },
            },
          }),
        ],
        1
      ),
      _c("PermissionPopup", {
        attrs: { permissionMetadata: _vm.permissionMetadata },
      }),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }