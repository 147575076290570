var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "650" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "div",
                {
                  staticClass:
                    "v-card__title headline v-modal-header d-flex justify-space-between",
                },
                [
                  _c("h5", { staticClass: "text-h6" }, [
                    _vm._v("Complete the Slack Integration"),
                  ]),
                  _c("button", {
                    staticClass:
                      "v-icon notranslate v-icon--link mdi mdi-close theme--light",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.dialog = false
                      },
                    },
                  }),
                ]
              ),
              _c("v-card-text", { staticClass: "pt-3" }, [
                _vm._v(
                  "Step 1: Within Slack, navigate to Settings & Administration → Manage Apps. "
                ),
                _c("br"),
                _vm._v(' Step 2: Select the "Search Ads Maven Test App" app. '),
                _c("br"),
                _vm._v(
                  " Step 3: Add the app to the channel which you chose for this integration. "
                ),
                _c("br"),
                _vm._v(
                  " Step 4: Click the Test button below to deliver a sample report to the channel. "
                ),
                _c("br"),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "text-right d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", depressed: "", width: "114" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        depressed: "",
                        width: "114",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sendSlackMsg(_vm.integrationID)
                        },
                      },
                    },
                    [_vm._v("Test")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "362" },
              model: {
                value: _vm.loadingAlertFlag,
                callback: function ($$v) {
                  _vm.loadingAlertFlag = $$v
                },
                expression: "loadingAlertFlag",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: { padding: "7px 0px" },
                  attrs: { color: "primary" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "cust", attrs: { color: "primary" } },
                    [
                      _c("label", { staticStyle: { color: "white" } }, [
                        _vm._v("Sending Message. Just a moment"),
                      ]),
                      _c("v-progress-linear", {
                        staticClass: "mb-0",
                        attrs: {
                          indeterminate: "",
                          color: "white",
                          stream: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("InfoAlert", {
        attrs: {
          InfoAlert: _vm.infoAlertFlag,
          InfoAlertTitle: _vm.infoAlertTitle,
          InfoAlertMessage: _vm.infoAlertMessage,
        },
        on: {
          emitInfoAlertOkClicked: function ($event) {
            _vm.infoAlertFlag = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }