var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }),
          _vm.mmpName != "kochava" && _vm.mmpName != "AppsFlyer"
            ? _c("v-card-text", [
                _vm._v("MMP support for "),
                _c("strong", [_vm._v(_vm._s(this.mmpName))]),
                _vm._v(
                  " is coming soon. If you have an immediate need to integrate with this MMP please reach out to "
                ),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v("support@searchadsmaven.com"),
                ]),
                _vm._v("."),
              ])
            : _vm._e(),
          _vm.mmpName == "kochava"
            ? _c("v-card-text", [
                _c(
                  "p",
                  {
                    staticStyle: { "text-align": "justify", color: "inherit" },
                  },
                  [_vm._v(_vm._s(_vm.$t("successMsg.integrationWarning")))]
                ),
              ])
            : _vm._e(),
          _vm.mmpName == "AppsFlyer"
            ? _c("v-card-text", [
                _vm._v("To integrate Appsflyer as your MMP, please contact "),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v("support@searchadsmaven.com"),
                ]),
                _vm._v("."),
              ])
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Ok ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }