























































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { MMP } from 'src/utils/enums';

export default Vue.extend({
  props: {
    apiKeyData: {
      type: Object,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      apiKey: null,
      appName: null,
      adamId: null,
      appIcon: null,
      from: null,
      mmpId: null,
      appEvents: [],
      MMP: MMP
    };
  },
  watch: {
    apiKeyData(data) {
      this.dialog = true;
      this.apiKey = data.apiKey;
      this.appName = data.selectedApp.appName;
      this.adamId = data.selectedApp.adamId;
      this.appIcon = data.selectedApp.appIcon;
      this.from = data.from;
      this.mmpId = data.mmpId
      this.appEvents = data.appEvents || []
      // console.log(this.appEvents)
    },
  },
  methods: {
    copyToClipboard() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$notify.success("API Key Copied")
    },
    copyToClipboard2() {
      this.$refs.adid.focus();
      document.execCommand('copy');
      this.$notify.success("APP ID Copied")
    },
    closeDialog() {
      this.dialog = false;
      if(this.from == 'selectAttrApps') {
        this.$root.$emit('mmpIntAdded', this.adamId, this.mmpId);
        this.$notify.success("Integration Successful")
      }
    }
  },
});
