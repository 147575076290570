


















































import { DELETE_MMP_INTEGRATION } from 'src/utils/apollo-mutations';
import Vue from 'vue';
import { LSS } from '@core/services';
export default Vue.extend({
  name: 'DeleteMmpIntegration',
  props: {
    integrationDetailsForDeletion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    integrationDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteIntegration() {
      this.deleting = true;
      try {
        const res = await this.$apollo.mutate({
          mutation: DELETE_MMP_INTEGRATION,
          variables: {
            mmpId: this.integrationDetailsForDeletion.mmpId,
            appId: this.integrationDetailsForDeletion.appId
          },
        });
        // console.log(res)
        if(res.data?.deleteAttrIntegration?.code == 0 && LSS.ownApps && LSS.ownApps.length > 0) { 
          let lssApps = [...LSS.ownApps]
          for (let index = 0; index < lssApps.length; index++) {
            const element = lssApps[index];  
            if(element.adamId == this.integrationDetailsForDeletion.appId){
              lssApps[index].attrExist = false
              lssApps[index].attrType = ''
            }
          }        
          LSS.ownApps = []
          LSS.ownApps = lssApps.filter(e => e.adamId != 0)
        }
        this.deleting = false;
        this.dialog = false;
        window.location.reload();
      } catch (error) {
        this.deleting = false;
      }
    },
  },
});
