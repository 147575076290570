



















































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { GENERATE_ATTR_API_KEY } from 'src/utils/apollo-mutations';
import { MMP } from 'src/utils/enums';
import GenerateApiKey from '../Integration-Steps/GenerateApiKey.vue'
import Vue from 'vue';
import { LSS } from '@core/services';
import { updateCLF } from '../../utils/util';
import { CheckListFields } from '@shared/enums/shared.enums'

export default Vue.extend({
  props: {
    selectAttrAppsData: {
      type: Object,
      required: false,
    },
  },
  components: { GenerateApiKey },
  data() {
    return {
      dialog: false,
      selectedApp: null,
      attrApps: [],
      apiKeyData: null,
      form: false,
      mmpType: ''
    };
  },
  watch: {
    dialog(value) {
      if(!value) this.$refs.selectionForm.reset()
    },
    selectAttrAppsData(data) {
      //console.log(data);

      this.dialog = true;
      this.mmpType = data.mmpType
      if(LSS.ownApps && LSS.ownApps.length > 0) {
        const filteredApps = LSS.ownApps.filter(e => !e.attrExist)
        // filteredApps.unshift({
        //   header: 'UnAttributed Apps',
        //   divider: true,
        // });
        this.attrApps = filteredApps
      }
    },
  },
  computed: {
    appRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('createCampaignRules.selectApp')];
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.selectedApp = null;
    },

    generateApiKey() {
      if(this.mmpType == 'kochava'){
        const attrReq = {
        appName: this.selectedApp.appName,
        appId: this.selectedApp.adamId,
        mmpId: MMP.KOCHAVA_ID,
        mmpName: MMP.KOCHAVA_NAME
      }

      this.$apollo
        .mutate({
          mutation: GENERATE_ATTR_API_KEY,
          variables: {
            attrReq: attrReq
          },
        })
        .then((res) => {
          const data = JSON.parse(res.data.generateAttrAPIKey.data)
          if(res.data.generateAttrAPIKey.code === 1) {
            this.$notify.error("Integration already exists");
          }
          else if(res.data.generateAttrAPIKey.code === 0 || 3) {
            const apiKey = data.apiKey;
            const appEvents = data.appEvents
            if(appEvents && appEvents.length > 0){
              updateCLF(CheckListFields['mmpIntegration'], this)
            }
            if(LSS.ownApps && LSS.ownApps.length > 0) {
              let lssApps = [...LSS.ownApps]
              for (let index = 0; index < lssApps.length; index++) {
                const element = lssApps[index];
                if(element.adamId == this.selectedApp.adamId){
                  lssApps[index].attrExist = true
                  lssApps[index].attrType = attrReq.mmpId
                }
              }
              LSS.ownApps = []
              LSS.ownApps = lssApps.filter(e => e.adamId != 0)
            }

            this.apiKeyData = {
              from: 'selectAttrApps',
              selectedApp: this.selectedApp,
              apiKey: apiKey,
              mmpId: MMP.KOCHAVA_ID,
              appEvents: appEvents
            };
          }
          else if(res.data.generateAttrAPIKey.code === 9 || 10) {
            this.$notify.error("Error while generating Api key");
          }
          else {
            this.$notify.error("Something went wrong");
          }

          this.dialog = false;

        })
        .catch((error) => {
          this.$notify.error("Something went wrong");
        });
      }
      else if(this.mmpType == 'branch'){
        const attrReq = {
              appName: this.selectedApp.appName,
              appId: this.selectedApp.adamId,
              mmpId: MMP.BRANCH_ID,
              mmpName: MMP.BRANCH_NAME
            }

        this.$apollo
          .mutate({
            mutation: GENERATE_ATTR_API_KEY,
            variables: {
              attrReq: attrReq
            },
          })
          .then((res) => {
            const data = JSON.parse(res.data.generateAttrAPIKey.data)
            if(res.data.generateAttrAPIKey.code === 1) {
              this.$notify.error("Integration already exists");
            }
            else if(res.data.generateAttrAPIKey.code === 0 || 3) {
              const apiKey = data.apiKey;
              const appEvents = data.appEvents
              if(appEvents && appEvents.length > 0){
                  updateCLF(CheckListFields['mmpIntegration'], this)
                }
              if(LSS.ownApps && LSS.ownApps.length > 0) {
                let lssApps = [...LSS.ownApps]
                for (let index = 0; index < lssApps.length; index++) {
                  const element = lssApps[index];
                  if(element.adamId == this.selectedApp.adamId){
                    lssApps[index].attrExist = true
                    lssApps[index].attrType = attrReq.mmpId
                  }
                }
                LSS.ownApps = []
                LSS.ownApps = lssApps.filter(e => e.adamId != 0)
              }

              this.apiKeyData = {
                from: 'selectAttrApps',
                selectedApp: this.selectedApp,
                apiKey: apiKey,
                mmpId: MMP.BRANCH_ID,
                appEvents: appEvents
              };
            }
            else if(res.data.generateAttrAPIKey.code === 9 || 10) {
              this.$notify.error("Error while generating Api key");
            }
            else {
              this.$notify.error("Something went wrong");
            }

            this.dialog = false;

          })
          .catch((error) => {
            this.$notify.error("Something went wrong");
          });
      }
      else if(this.mmpType == 'custom'){
        const attrReq = {
              appName: this.selectedApp.appName,
              appId: this.selectedApp.adamId,
              mmpId: MMP.CUSTOM_ID,
              mmpName: MMP.CUSTOM_NAME
            }

        this.$apollo
          .mutate({
            mutation: GENERATE_ATTR_API_KEY,
            variables: {
              attrReq: attrReq
            },
          })
          .then((res) => {
            const data = JSON.parse(res.data.generateAttrAPIKey.data)
            if(res.data.generateAttrAPIKey.code === 1) {
              this.$notify.error("Integration already exists");
            }
            else if(res.data.generateAttrAPIKey.code === 0 || 3) {
              const apiKey = data.apiKey;
              const appEvents = data.appEvents
              if(appEvents && appEvents.length > 0){
                  updateCLF(CheckListFields['mmpIntegration'], this)
                }
              if(LSS.ownApps && LSS.ownApps.length > 0) {
                let lssApps = [...LSS.ownApps]
                for (let index = 0; index < lssApps.length; index++) {
                  const element = lssApps[index];
                  if(element.adamId == this.selectedApp.adamId){
                    lssApps[index].attrExist = true
                    lssApps[index].attrType = attrReq.mmpId
                  }
                }
                LSS.ownApps = []
                LSS.ownApps = lssApps.filter(e => e.adamId != 0)
              }

              this.apiKeyData = {
                from: 'selectAttrApps',
                selectedApp: this.selectedApp,
                apiKey: apiKey,
                mmpId: MMP.CUSTOM_ID,
                appEvents: appEvents
              };
            }
            else if(res.data.generateAttrAPIKey.code === 9 || 10) {
              this.$notify.error("Error while generating Api key");
            }
            else {
              this.$notify.error("Something went wrong");
            }

            this.dialog = false;

          })
          .catch((error) => {
            this.$notify.error("Something went wrong");
          });
      }

    }
  },
});
