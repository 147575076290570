






















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
export default Vue.extend({
  props: {
    comingSoonMetadata: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      mmpName: null,
      dialog: false,
    };
  },
  watch: {
    comingSoonMetadata(data) {
      this.dialog = true;
      this.mmpName = data.mmpName
    },
  },
  methods: {},
});
