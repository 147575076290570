var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "900" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: " " },
            [
              _c(
                "div",
                {
                  staticClass:
                    "v-card__title headline v-modal-header d-flex justify-space-between",
                },
                [
                  _vm.mmpId == _vm.MMP.KOCHAVA_ID
                    ? _c("h5", { staticClass: "text-h6" }, [
                        _vm._v(
                          _vm._s(_vm.$t("integration.integrateWith")) +
                            " Kochava"
                        ),
                      ])
                    : _vm._e(),
                  _vm.mmpId == _vm.MMP.BRANCH_ID
                    ? _c("h5", { staticClass: "text-h6" }, [
                        _vm._v(
                          _vm._s(_vm.$t("integration.integrateWith")) +
                            " Branch"
                        ),
                      ])
                    : _vm._e(),
                  _vm.mmpId == _vm.MMP.CUSTOM_ID
                    ? _c("h5", { staticClass: "text-h6" }, [
                        _vm._v(
                          _vm._s(_vm.$t("integration.integrateWith")) +
                            " Custom"
                        ),
                      ])
                    : _vm._e(),
                  _c("button", {
                    staticClass:
                      "v-icon notranslate v-icon--link mdi mdi-close theme--light",
                    staticStyle: { cursor: "pointer" },
                    attrs: { type: "button" },
                    on: { click: _vm.closeDialog },
                  }),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "border-right",
                      attrs: { col: "12", lg: "8", md: "6" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "partner-app d-flex align-center justify-center pt-3 pb-2",
                        },
                        [
                          _vm.mmpId == _vm.MMP.KOCHAVA_ID
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "partner-integrate d-flex align-center px-4 flex-column",
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: require("../../assets/images/kochava.png"),
                                      width: "50px",
                                      height: "50px",
                                    },
                                  }),
                                  _c("h6", [_vm._v("Kochava")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mmpId == _vm.MMP.BRANCH_ID
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "partner-integrate d-flex align-center px-4 flex-column",
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: require("../../assets/images/branch_logo.png"),
                                      width: "50px",
                                      height: "50px",
                                    },
                                  }),
                                  _c("h6", [_vm._v("Branch")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mmpId == _vm.MMP.CUSTOM_ID
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "partner-integrate d-flex align-center px-4 flex-column",
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: require("../../assets/images/custom.png"),
                                      width: "50px",
                                      height: "50px",
                                    },
                                  }),
                                  _c("h6", [_vm._v("Custom")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-icon", { staticClass: " px-4" }, [
                            _vm._v("mdi-arrow-right-thick"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "integrate-app d-flex align-center px-4 flex-column ",
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.appIcon,
                                  "lazy-src": _vm.appIcon,
                                  height: "50px",
                                  width: "50px",
                                },
                              }),
                              _c("h6", [_vm._v(_vm._s(_vm.appName))]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-title",
                        { staticClass: "text-h6 primary--text py-0" },
                        [_vm._v(_vm._s(_vm.$t("integration.followIntSteps")))]
                      ),
                      _vm.mmpId == _vm.MMP.KOCHAVA_ID
                        ? _c("v-card-text", { staticClass: "px-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("integration.kochavaStep1")) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep2")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep3")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep4")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep5")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep6")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep7")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("integration.kochavaStep8")) + " "
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _vm.mmpId == _vm.MMP.BRANCH_ID
                        ? _c("v-card-text", { staticClass: "px-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("integration.branchStep")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.mmpId == _vm.MMP.CUSTOM_ID
                        ? _c("v-card-text", { staticClass: "px-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("integration.customStep")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "field px-3" },
                        [
                          _c("v-text-field", {
                            ref: "clone",
                            attrs: {
                              label: _vm.$t("integration.apiKey"),
                              placeholder: _vm.$t("integration.apiKey"),
                              "append-icon":
                                "mdi-checkbox-multiple-blank-outline",
                              outlined: "",
                              dense: "",
                              readonly: "",
                              value: _vm.apiKey,
                            },
                            on: {
                              focus: function ($event) {
                                return $event.target.select()
                              },
                              "click:append": _vm.copyToClipboard,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "field px-3" },
                        [
                          _c("v-text-field", {
                            ref: "adid",
                            attrs: {
                              label: _vm.$t("integration.appStoreId"),
                              placeholder: _vm.$t("integration.appStoreId"),
                              outlined: "",
                              dense: "",
                              readonly: "",
                              "append-icon":
                                "mdi-checkbox-multiple-blank-outline",
                            },
                            on: {
                              "click:append": _vm.copyToClipboard2,
                              focus: function ($event) {
                                return $event.target.select()
                              },
                            },
                            model: {
                              value: _vm.adamId,
                              callback: function ($$v) {
                                _vm.adamId = $$v
                              },
                              expression: "adamId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-end mt-0 pt-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "w-xsm-100 mb-2",
                              staticStyle: { width: "120px" },
                              attrs: { color: "primary", elevation: "0" },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(_vm._s(_vm.$t("integration.close")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { col: "12", lg: "4", md: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: " px-3", attrs: { elevation: "0" } },
                        [
                          _vm.appEvents.length > 0
                            ? _c("p", { staticClass: "py-2 dark-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.appEvents.length) +
                                    " " +
                                    _vm._s(
                                      _vm.$t("integration.distinctEventType")
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm.appEvents.length == 0
                            ? _c("p", { staticClass: "py-2 dark-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("integration.noDistinctEventType")
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              staticClass:
                                "distinct-outer pa-0 h-80 border-a d-flex flex-column align-start rounded",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "distinct-type grey--text px-1",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("integration.dEventType")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass:
                                    " align-content-start w-100 scrolly px-3",
                                },
                                [
                                  _vm.appEvents.length == 0
                                    ? _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("integration.noEventFound")
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.appEvents,
                                    function (appEvent, index) {
                                      return [
                                        _c(
                                          "v-card",
                                          {
                                            key: index,
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(appEvent.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }